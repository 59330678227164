export const API_URL = {
  LOGIN: "/auth/getClientToken",
  CHECK_LOGIN: "/auth/checkExistingLoginSession",
  GET_SESSION: "/api/APIAppManager/LoginSession/AddLoginSession",
  GET_USER: "/api/APIAppManager/User/GetUsers",
  USER_FACILITY: "/api/APIAppManager/User/GetFacilities",
  GET_CONTROL: "/api/APIAppManager/Control/GetControls",
  GET_PATIENT_ALERT: "/api/APIgeneralmaster/PatientAlert/GetPatientAlerts",
  LOGOUT: "/auth/ClientLogout",
  REFRESH_TOKEN: "/auth/refresh",
  GET_EVENT_TEMPLATES: "/api/APIAppManager/EventTemplate/GetEventTemplates",
  ADD_EVENT_TEMPLATES: "/api/APIAppManager/EventTemplate/AddEventTemplate",
  VIEW_EVENT_TEMPLATES: "/api/APIAppManager/EventTemplate/GetEventTemplateById",
  UPDATE_EVENT_TEMPLATES:
    "/api/APIAppManager/EventTemplate/UpdateEventTemplate",
  DELETE_EVENT_TEMPLATES:
    "/api/APIAppManager/EventTemplate/DeleteEventTemplate",
  GET_ORGANIZATION: "/api/APIAppManager/Organization/GetOrganizationList",
  ADD_ORGANIZATION: "/api/APIAppManager/Organization/AddOrganization",
  VIEW_ORGANIZATION: "/api/APIAppManager/Organization/GetOrganizationById",
  UPDATE_ORGANIZATION: "/api/APIAppManager/Organization/UpdateOrganization",
  DELETE_ORGANIZATION: "/api/APIAppManager/Organization/DeleteOrganization",
  ADD_LOGO_ORGANIZATION:
    "/api/APIAppManager/Organization/AddfileforOrganization",
  GET_LOGO_ORGANIZATION: "/api/APIAppManager/Organization/GetOrganizationLogo",
  GET_FACILITY: "/api/APIAppManager/Facility/GetFacilitys",
  ADD_FACILITY: "/api/APIAppManager/Facility/AddFacility",
  VIEW_FACILITY: "/api/APIAppManager/Facility/GetFacilityById",
  UPDATE_FACILITY: "/api/APIAppManager/Facility/UpdateFacility",
  UPLOAD_FILE_FACILITY: "/api/APIAppManager/Facility/AddfileforFacility",
  GET_FACILITY_FIRST_LOGO: "/api/APIAppManager/Facility/GetFacilityLogo",
  GET_FACILITY_SECOND_LOGO: "/api/APIAppManager/Facility/GetSecondFacilityLogo",
  FACILITY_MAP_DEPARTMENT: "/api/APIAppManager/Facility/MapDepartments",
  FACILITY_GET_DEPARTMENT: "/api/APIAppManager/Facility/GetDepartments",
  FACILITY_PREFERENCE_TYPES:
    "/api/APIAppManager/FacilityPreference/GetFacilityPreferences",
  FACILITY_ADD_PREFERENCE:
    "/api/APIAppManager/FacilityPreference/ManageFacilityPreferences",
  GET_FACILITY_DEFAULT_SERVICES:
    "/api/APIAppManager/facilitydefaultservice/GetFacilityDefaultServices",
  ADD_DEFAULT_SERVICES:
    "/api/APIAppManager/facilitydefaultservice/ManageFacilityDefaultService",
  FACILITY_GET_DEFAULT_SERVICES_ITEMS:
    "/api/APIClinicalMaster/ServiceItem/GetServiceItems",
  GET_FACILITY_SETINGS:
    "/api/APIAppManager/facilitysetting/GetFacilitySettings",
  ADD_FACILITY_SETINGS: "/api/APIAppManager/facilitysetting/AddFacilitySetting",
  UPDATE_FACILITY_SETINGS:
    "/api/APIAppManager/facilitysetting/UpdateFacilitySetting",
  GET_FACILITY_SPECIAL_HOLIDAY_SETTINGS: "/api/APIHRM/Holiday/GetHolidays",
  ADD_HOLIDAYS: "/api/APIHRM/Holiday/AddHoliday",
  GET_HOLIDAY: "/api/APIHRM/Holiday/GetHolidayById",
  UPDATE_HOLIDAYS: "/api/APIHRM/Holiday/UpdateHoliday",
  DELETE_HOLIDAYS: "/api/APIHRM/Holiday/DeleteHoliday",
  GET_DEPARTMENTS: "/api/APIAppManager/department/GetDepartments",
  ADD_DEPARTMENT: "/api/APIAppManager/department/AddDepartment",
  VIEW_DEPARTMENT: "/api/APIAppManager/department/GetDepartmentById",
  UPDATE_DEPARTMENT: "/api/APIAppManager/department/UpdateDepartment",
  ADD_DEPARTMENT_LOGO: "/api/APIAppManager/department/AddfileforDepartment",
  GET_DEPARTMENT_LOGO: "/api/APIAppManager/department/GetDepartmentLogo",
  GET_ROLES: "/api/APIAppManager/Role/GetRoles",
  ADD_ROLE: "/api/APIAppManager/Role/AddRole",
  VIEW_ROLE: "/api/APIAppManager/Role/GetRoleById",
  UPDATE_ROLE: "/api/APIAppManager/Role/UpdateRole",
  ROLES_MAP_FACILITY: "/api/APIAppManager/Role/MapFacilities",
  ROLES_GET_FACILITY: "/api/APIAppManager/Role/GetFacilities",
  GET_SPECIAL_PRIVILEGES: "/api/APIAppManager/RolePrivilege/GetRolePrivileges",
  ADD_SPECIAL_PRIVILEGES:
    "/api/APIAppManager/RolePrivilege/ManageRolePrivilege",
  ROLES_CONTROLS_GET_MAP: "/api/APIAppManager/Role/GetControls",
  ROLES_CONTROLS_MAP: "/api/APIAppManager/Role/MapControls",
  ROLES_GET_ALL_REPORTS: "/api/APIGeneralMaster/Report/GetReports",
  ROLES_GET_REPORTS: "/api/APIAppManager/User/GetReports",
  ROLES_MAP_REPORTS: "/api/APIAppManager/User/MapReports",
  GET_GROUPS: "/api/APIAppManager/Group/GetGroups",
  ADD_GROUP: "/api/APIAppManager/Group/AddGroup",
  VIEW_GROUP: "/api/APIAppManager/Group/GetGroupById",
  UPDATE_GROUP: "/api/APIAppManager/Group/UpdateGroup",
  DELETE_GROUP: "/api/APIAppManager/Group/DeleteGroup",
  GROUPS_MAP_FACILITY: "/api/APIAppManager/Group/MapFacilities",
  GROUPS_GET_FACILITY: "/api/APIAppManager/Group/GetFacilities",
  GROUPS_MAP_ROLES: "/api/APIAppManager/Group/MapRoles",
  GROUPS_GET_ROLES: "/api/APIAppManager/Group/GetRoles",
  GET_SPECIALITY: "/api/APIAppManager/Speciality/GetSpecialitys",
  ADD_SPECIALITY: "/api/APIAppManager/Speciality/AddSpeciality",
  GET_USERS: "/api/APIAppManager/User/GetUsers",
  ADD_USER: "/api/APIAppManager/User/AddUser",
  UPDATE_USER: "/api/APIAppManager/User/UpdateUser",
  VIEW_USER: "/api/APIappmanager/User/GetUserById",
  USER_GET_EMPLOYEES: "/api/APIHRM/Employee/GetEmployees",
  USER_MAP_DEPARTMENT: "/api/APIAppManager/User/MapDepartments",
  USER_GET_DEPARTMENT: "/api/APIAppManager/User/GetDepartments",
  USER_MAP_FACILITY: "/api/APIAppManager/User/MapFacilities",
  USER_GET_FACILITY: "/api/APIAppManager/User/GetFacilities",
  USER_MAP_SPECIALITY: "/api/APIAppManager/User/MapSpecialities",
  USER_GET_SPECIALITY: "/api/APIAppManager/User/GetSpecialities",
  USER_ADD_TEAMS: "/api/APIAppManager/UserTeam/ManageUserTeams",
  USER_GET_TEAMS: "/api/APIAppManager/UserTeam/GetUserTeams",
  USER_DELETE_TEAMS: "/api/APIAppManager/UserTeam/DeleteUserTeam",
  USER_ADD_TAX_DETAILS: "/api/APIAppManager/UserTaxDetail/AddUserTaxDetail",
  USER_VIEW_TAX_DETAILS: "/api/APIAppManager/UserTaxDetail/GetUserTaxDetails",
  USER_UPDATE_TAX_DETAILS: "/api/APIAppManager/UserTaxDetail/UpdateUserTaxDetail",
  USER_ADD_DEFAULT_SERVICE: "/api/APIAppManager/UserDefaultService/ManageUserDefaultService",
  USER_GET_DEFAULT_SERVICE: "/api/APIAppManager/UserDefaultService/GetUserDefaultServices",
  USER_DELETE_DEFAULT_SERVICE: "/api/APIAppManager/UserDefaultService/DeleteUserDefaultService",
  GET_PASSWORD_RESET: "/api/APIAppManager/User/GetUsers",
  GET_RESET_PASSWORD: "/api/APIAppManager/User/ResetPassword",
  TODAY_INFORMATION:
    "/appmanager/facilitydashboard/GetFacilityDashboardOptions",
  GET_OPTIONS: "/api/Lookup/Options/getoptions",
  GET_COUNTRY: "/api/APIGeneralMaster/CountryMaster/GetCountryMasters",
  GET_STATE: "/api/APIGeneralMaster/StateMaster/GetStateMasters",
  GET_CITY: "/api/APIGeneralMaster/CityMaster/GetCityMasters",
  GET_PINCODE: "/api/APIGeneralMaster/PincodeMaster/GetPincodeMasters",
  GET_REFERENCE_GROUPS:
    "/api/APIAppManager/ReferenceValueGroup/GetReferenceValueGroups",
  REFERENCE_GROUP_VIEW: "/api/APIAppManager/ReferenceValueGroup/GetReferenceValueGroupById",
  REFERENCE_GROUP_ADD: "/api/APIAppManager/ReferenceValueGroup/AddReferenceValueGroup",
  REFERENCE_GROUP_UPDATE: "/api/APIAppManager/ReferenceValueGroup/UpdateReferenceValueGroup", 
  GET_REFERENCE_VALUE: "/api/APIAppManager/ReferenceValue/GetReferenceValues",
  REFERENCE_VALUE_VIEW: "/api/APIAppManager/ReferenceValue/GetReferenceValueById",
  REFERENCE_VALUE_DELETE: "/api/APIAppManager/ReferenceValue/DeleteReferenceValue",
  REFERENCE_VALUE_ADD: "/api/APIAppManager/ReferenceValue/AddReferenceValue",
  REFERENCE_VALUE_UPDATE: "/api/APIAppManager/ReferenceValue/UpdateReferenceValue",
  GET_MODULES: "/api/APIAppManager/Module/GetModules",
  ADD_MODULE: "/api/APIAppManager/Module/AddModule",
  VIEW_MODULE: "/api/APIAppManager/Module/GetModuleById",
  UPDATE_MODULE: "/api/APIAppManager/Module/UpdateModule",
  DELETE_MODULE: "/api/APIAppManager/Module/DeleteModule",
  GET_MODULES_SCREEN: "/api/APIAppManager/Screen/GetScreens",
  ADD_MODULE_SCREEN: "/api/APIAppManager/Screen/AddScreen",
  VIEW_MODULE_SCREEN: "/api/APIAppManager/Screen/GetScreenById",
  UPDATE_MODULE_SCREEN:"/api/APIAppManager/Screen/UpdateScreen",
  DELETE_MODULE_SCREEN: "/api/APIAppManager/Screen/DeleteScreen",
  GET_ERP_OUTBOUND: "/api/APIGeneralMaster/Guarantor/GetGuarantors",
  GET_ERP_INBOUND: "/api/APIInventory/VendorMaster/GetVendorMasters",
  GET_CONTROLS: "/api/APIAppManager/Control/GetControls",
  VIEW_CONTROL: "/api/APIAppManager/Control/GetControlById",
  ADD_CONTROL:"/api/APIAppManager/Control/AddControl",
  UPDATE_CONTROL: "/api/APIAppManager/Control/UpdateControl",
  GET_ACTIVE_USERS: "/auth/getActiveUsers",
  GET_SEQUENCE_MASTERS: "/api/APIGeneral/SequenceMasters/GetSequenceMasterss",
  ADD_SEQUENCE_MASTER: "/api/APIGeneral/SequenceMasters/AddSequenceMasters",
  SQL_TO_REDIS_SEQUENCE:
    "/api/APIGeneral/SequenceMasters/SyncSqlToRedisSequenceMasters",
  REDIS_TO_SQL_SEQUENCE:
    "/api/APIGeneral/SequenceMasters/SyncRedisToSqlSequenceMasters",
  GET_PRINT_LOGS: "/api/APIGeneral/EntityPrintHistory/GetEntityPrintHistorys",
  SPECIALITY_ADD: "/api/APIAppManager/Speciality/AddSpeciality",
  SPECIALITY_UPDATE: "/api/APIAppManager/Speciality/UpdateSpeciality",
  SPECIALITY_DELETE: "/api/APIAppManager/Speciality/DeleteSpeciality",
  SPECIALITY_VIEW: "/api/APIAppManager/Speciality/GetSpecialityById",
  SPECIALITY_GET: "/api/APIAppManager/Speciality/GetSpecialitys",
  ADD_REPORT_SCHEDULARS: "/api/APIAppManager/ReportSchedular/AddReportSchedular",
  VIEW_REPORT_SCHEDULARS: "/api/APIAppManager/ReportSchedular/GetReportSchedularById",
  UPDATE_REPORT_SCHEDULARS: "/api/APIAppManager/ReportSchedular/UpdateReportSchedular",
  GET_REPORT_SCHEDULARS:
  "/api/APIAppManager/ReportSchedular/GetReportSchedulars",  
  DELETE_REPORT_SCHEDULARS:
  "/api/APIAppManager/ReportSchedular/DeleteReportSchedular",
  GET_UNIT_DEPARTMENT_MAPPING: '/api/APIAppManager/DepartmentUnitDays/GetDepartmentUnitDayss',
  GET_UNIT_DEPARTMENT_MAPPING_DOCTOR_LIST: "/api/APIAppManager/User/GetUsers",
  DELETE_UNIT_DEPARTMENT_MAPPING:'/api/APIAppManager/DepartmentUnitDays/DeleteDepartmentUnitDays',
  ADD_UNIT_DEPARTMENT_MAPPING: "/api/APIAppManager/DepartmentUnitDays/AddDepartmentUnitDays",
  UPDATE_UNIT_DEPARTMENT_MAPPING: "/api/APIAppManager/DepartmentUnitDays/UpdateDepartmentUnitDays",
  VIEW_UNIT_DEPARTMENT_MAPPING: "/api/APIAppManager/DepartmentUnitDays/GetDepartmentUnitDaysById",
  ADD_EVENT_DASHBOARD: "/api/APIAppmanager/EventDashboard/AddEventDashboard",
  VIEW_EVENT_DASHBOARD: "/api/APIAppManager/EventDashboard/GetEventDashboardById",
  UPDATE_EVENT_DASHBOARD: "/api/APIAppmanager/EventDashboard/UpdateEventDashboard",
  GET_EVENT_DASHBOARD: "/api/APIAppManager/EventDashboard/GetEventDashboards",
  DELETE_EVENT_DASHBOARD: "/api/APIAppManager/EventDashboard/DeleteEventDashboard",
};
